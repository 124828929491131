import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import Layout from 'components/layout'
import TextContainer from 'components/common/text-container'
import Navigation from 'components/navigation'
import { Heading } from 'components/common/typography'
import { gray } from 'components/theme/colours'

const LinkButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  border-color: ${gray};
  border-width: 2px;
  border-style: solid;
  text-align: center;
  outline: 0;
  padding: 10px 10px;
  width: 100%;
  margin-bottom: 8px;
  max-width: 600px;
`

const Spacer = styled.div`
  height: 60px;
`

const LinksPage = ({ data }) => (
  <Layout>
    <Helmet
      title='Links | Pinya Letters'
      meta={[
        {
          name: 'description',
          content: 'Links to latest features and more!',
        },
      ]}
    />
    <Navigation solidNav />
    <Spacer />
    <TextContainer>
      <Heading>Links</Heading>
      {data.links.edges.map(({ node }) => (
        <LinkButton key={node.id} href={node.link}>
          {node.title}
        </LinkButton>
      ))}
    </TextContainer>
  </Layout>
)

export const query = graphql`
  query LinksPageQuery {
    links: allContentfulLink(sort: { fields: createdAt, order: DESC }) {
      edges {
        node {
          id
          title
          link
        }
      }
    }
  }
`

export default LinksPage
